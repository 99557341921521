/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser } from "api"
import { useState } from "react"
import CustomButton from "./CustomButton"
import { iconPhone } from "lib/image"
import "./index.css"

const OTPCode = ({ email, type, onNext }) => {
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const onFinish = async (e) => {
    if (loading) {
      return
    }
    e.preventDefault()
    try {
      setLoading(true)
      const res = await apiUser.verifyCode({
        email: email.trim(),
        code: code,
        mail_type: type
      })
      const { access_token, refresh_token, organization_id } = res.data
      onNext({
        organization_id,
        access_token,
        refresh_token
      })
    } catch (error) {
      const { error_message } = error
      setError(error_message[0])
    } finally {
      setLoading(false)
    }
  }

  const onChange = (v) => {
    setCode(v)
  }

  const onResend = async () => {
    if (loading) {
      return
    }

    try {
      setLoading(true)
      await apiUser.sendEmail({
        email: email.trim(),
        mail_type: type
      })
    } catch (error) {
      const { error_message } = error
      setError(error_message[0])
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-[30px] text-[#171A1F] font-[700] leading-tight tracking-tight text-center">
          OTP Code
        </h1>
        <div className="margin-text-top text-center text-[14px] font-[400] text-[#171A1F]">
          Enter your OTP that you are received from your email and then we can change password.
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              Your code
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={code}
              type="text"
              name="code"
              id="code"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder="abczys"
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconPhone}</span>
          </div>
          {error !== "" && <div className="text-red-500">{error}</div>}
          <div
            className="text-[14px] hover:underline hover:cursor-pointer text-[#000000E0] text-center"
            onClick={onResend}
          >
            Resend OTP?
          </div>
          <CustomButton title="Next" loading={loading} disabled={error !== "" ? true : false} />
        </form>
      </div>
    </div>
  )
}

export default OTPCode
