/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser } from "api"
import { useState } from "react"
import { EMAIL_TYPE } from "lib/Const"
// import { isEmailValid } from "lib/validate"
import CustomButton from "./CustomButton"
import { iconEmail, iconBack } from "lib/image"
import "./index.css"

const SendEmail = ({ isFirstLogin, isLockedUser, onNext, onBack }) => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const onFinish = async (e) => {
    e.preventDefault()
    if (loading) {
      return
    }
    // if (!isEmailValid(email)) {
    //   setError("Email is invalid!")
    //   return
    // }
    try {
      setLoading(true)
      await apiUser.sendEmail({
        email: email.trim(),
        mail_type: isFirstLogin ? EMAIL_TYPE.Confirm : EMAIL_TYPE.Reset
      })
      onNext(email)
    } catch (error) {
      const { error_message } = error
      setError(error_message[0])
    } finally {
      setLoading(false)
    }
  }

  const onChange = (v) => {
    setEmail(v)
  }

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-[30px] text-[#171A1F] font-[700] leading-tight tracking-tight text-center">
          {isFirstLogin ? "First Login?" : isLockedUser ? "Locked User" : "Forgot your password?"}
        </h1>
        <div className="margin-text-top text-center text-[14px] font-[400] text-[#171A1F]">
          {isLockedUser &&
            "Your account was locked out because you logged in incorrectly more than 5 times. "}
          Enter your email so that we can send you password reset link.
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              Email
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={email}
              type="email"
              name="email"
              id="email"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder="me.g. username@kinety.com"
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconEmail}</span>
          </div>
          {error !== "" && <div className="margin-text-top text-red-500">{error}</div>}
          <CustomButton
            title="Send recovery link"
            loading={loading}
            disabled={error !== "" ? true : false}
          />
          <div className="text-center">
            <div
              className="hover:cursor-pointer flex justify-center py-2 border-[1px]"
              onClick={onBack}
            >
              {iconBack}
              <span>Back to Login</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SendEmail
