import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import "./index.css"
import menus from "pages/Authen/menus"
import { isDriver, isAdmin } from "lib/function"

function convertMenus(menus, user) {
  let __menus = []
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const IconX = () => <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
    if (isDriver(user) || isAdmin(user)) {
      __menus.push({
        url: menu.url,
        title: menu.title,
        icon: <Icon component={IconX} />
      })
    }
  }

  return __menus
}

function MyLayout({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }
  return (
    <div className="flex min-h-screen">
      <Navbar items={convertMenus(menus, user)} isNav={isNav} />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-between bg-white border-b py-2 pr-6 pl-4 h-14 w-full sticky top-0 z-20">
          <div
            onClick={togleSidebar}
            className={`text-gray-950 rounded cursor-pointer flex items-center`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div>

          <MyHeader user={user} />
        </header>
        <div className="p-6">
          {/* {useLocation()?.pathname !== "/projects" && (
            <div className="mb-2 wapper_small">
              <DropdownProjects />
            </div>
          )} */}

          {children}
        </div>
      </section>
    </div>
  )
}
export default MyLayout
