import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"
const UserProfile = lazy(() => import("pages/UserProfile"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const Dashboard = lazy(() => import("pages/Dashboard"))
const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const ChauffuerConnect = lazy(() => import("pages/ChauffeurConnect"))
const ReportList = lazy(() => import("pages/ReportList"))
const ReportExpenseUpdate = lazy(() => import("pages/ReportList/ReportExpenseUpdate"))
const PassengerManagement = lazy(() => import("pages/PassengerManagement"))
const EmergencyContact = lazy(() => import("pages/EmergencyContact"))
const CreateRide = lazy(() => import("pages/ChauffeurConnect/Ride/CreateRide"))
const CreateMeeting = lazy(() => import("pages/ChauffeurConnect/Meeting/CreateMeeting"))
const CreateAirport = lazy(() => import("pages/ChauffeurConnect/Airport/CreateAirport"))
const CreateRental = lazy(() => import("pages/ChauffeurConnect/Rental/CreateRental"))
const CreatePackage = lazy(() => import("pages/ChauffeurConnect/Package/CreatePackage"))
const ChaffuerConnectCalander = lazy(() => import("pages/ChauffeurConnect/ChaffeurConnectCalander"))
const ViewChauffeurConnect = lazy(() => import("pages/ChauffeurConnect/ViewChauffuerConnect"))
//____ListPage
function pageList(__role) {
  return [
    {
      path: "/",
      Element: ChauffuerConnect,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      Element: UserProfile,
      path: "/profile",
      code: "PROFILE_CONTROLLER"
    },
    {
      Element: Security,
      path: "/profile/security",
      code: "PROFILE_CONTROLLER"
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal_data_history",
      code: "PROFILE_CONTROLLER"
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER"
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER"
    },
    {
      path: "activity_logs",
      Element: ActivityLogs,
      resource: "APPROVAL_CONTROLLER"
    },
    {
      path: "activity_logs/:log_id",
      Element: ViewLog,
      resource: "APPROVAL_CONTROLLER"
    },
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "/work/report",
      Element: ReportList,
      resource: RESOURCE_KEY.ROOT_WORK,
      isGuard: true
    },
    {
      path: "/work/report/action",
      Element: ReportExpenseUpdate,
      resource: RESOURCE_KEY.ROOT_WORK,
      isGuard: true
    },
    {
      path: "/chauffeur-connect",
      Element: ChauffuerConnect,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/calendar",
      Element: ChaffuerConnectCalander,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/action",
      Element: ViewChauffeurConnect,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/ride/action",
      Element: CreateRide,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/meeting/action",
      Element: CreateMeeting,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/airport/action",
      Element: CreateAirport,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/rental/action",
      Element: CreateRental,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/package/action",
      Element: CreatePackage,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/passenger",
      Element: PassengerManagement,
      resource: RESOURCE_KEY.ROOT_SERVICE,
      isGuard: true
    },
    {
      path: "/emergency-contact",
      Element: EmergencyContact,
      resource: RESOURCE_KEY.ROOT_SERVICE,
      isGuard: true
    }
  ]
}

export default pageList
