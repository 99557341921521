/* eslint-disable jsx-a11y/anchor-is-valid */
import CustomButton from "./CustomButton"
import { logo } from "lib/image"
import "./index.css"

const PasswordSuccess = ({ onNext }) => {
  const onFinish = async (e) => {
    e.preventDefault()
    onNext()
  }

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <div className="flex justify-center">{logo}</div>
        <h1 className="text-[30px] text-[#171A1F] font-[700] leading-tight tracking-tight text-center">
          Password changed!
        </h1>
        <div className="margin-text-top text-center text-[14px] font-[400] text-[#171A1F]">
          Completed Your Password Reset!
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <CustomButton title="Log In Now" />
        </form>
      </div>
    </div>
  )
}

export default PasswordSuccess
