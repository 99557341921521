import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/client/expense-category"

function gets(params) {
  return Client("root_work.service_path_view").get(`${resource}/list/`, { params })
}
function get(id) {
  return Client("root_work.service_path_detail").get(`${resource}/retrieve/${id}/`)
}
function create(data) {
  return Client("root_work.service_path_create").post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client("root_work.service_path_update").put(`${resource}/retrieve/${id}/`, data)
}

function remove(id) {
  return Client("root_work.service_path_delete").delete(`${resource}/retrieve/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove
}
export default api
