import Client from "../client/ClientChauffeurEngine"
import version from "../version"
const resource = version + "/staff"
const resourcePassenger = version + "/passenger"

function gets(params) {
  return Client("root_vehicle.service_path_view").get(`${resourcePassenger}/passenger/list/`, {
    params
  })
}
function get() {
  return Client("root_vehicle.service_path_view").get(`${resourcePassenger}/me/`)
}
function getRatings(params) {
  return Client("root_vehicle.service_path_view").get(`${resourcePassenger}/rating/me/`, { params })
}
function getStatistic(params) {
  return Client("root_vehicle.service_path_view").get(`${resourcePassenger}/rating/statistic/me/`, {
    params
  })
}
function getActivityStatistic(params) {
  return Client("root_vehicle.service_path_view").get(
    `${resourcePassenger}/activity-log/statistic/me/`,
    {
      params
    }
  )
}
function remove(id) {
  return Client("root_vehicle.service_path_view").delete(`${resource}/passenger/${id}/`)
}
function create(data) {
  return Client("root_vehicle.service_path_view").post(`${resource}/passenger/`, data)
}
function update(id, data) {
  return Client("root_vehicle.service_path_view").put(`${resource}/passenger/${id}/`, data)
}
function sendInvitation(id, data) {
  return Client("root_vehicle.service_path_view").put(
    `${resource}/passenger/send-invitation/${id}/`
  )
}

const api = {
  gets,
  get,
  getRatings,
  getStatistic,
  getActivityStatistic,
  create,
  update,
  sendInvitation,
  remove
}
export default api
