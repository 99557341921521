// import Client from "../client/ClientControlPlane"
import version from "../version"
import ClientChauffeur from "../client/ClientChauffeur"
const resource = version + "/staff"

function gets(params) {
  return ClientChauffeur().get(`${resource}/device/list/`, { params })
}
function getDetaiDevice(id) {
  return ClientChauffeur().get(`${resource}/device/retrieve/${id}/`)
}
function createDevice(data) {
  return ClientChauffeur("root_service.service_path_create").post(
    `${resource}/device/create/`,
    data
  )
}
function updateDevice(id, data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/device/retrieve/${id}/`,
    data
  )
}

function removeDevice(id, data) {
  return ClientChauffeur("root_service.service_path_update").delete(
    `${resource}/device/retrieve/${id}/`,
    data
  )
}

function updateStatusDevice(id, data) {
  return ClientChauffeur().put(`${resource}/device/retrieve/${id}/update-status`, data)
}
function exportDevice(data) {
  return ClientChauffeur().post(`${resource}/device/export/`, data)
}

function getSims(params) {
  return ClientChauffeur().get(`${version}/staff/sim/list/`, { params })
}
function getDetailSim(id) {
  return ClientChauffeur().get(`${version}/staff/sim/retrieve/${id}/`)
}

function updateStatusSim(id, data) {
  return ClientChauffeur().put(`${resource}/sim/retrieve/${id}/update-status`, data)
}

function importSim(formData) {
  return ClientChauffeur().post(`${resource}/sim/import/`, formData)
}

function remove(id) {
  return ClientChauffeur("root_service.service_path_delete").delete(`${resource}/${id}/`)
}

function updateMutil(data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/change-status/multiple/`,
    data
  )
}
function removeMutil(data) {
  return ClientChauffeur("root_service.service_path_delete").put(
    `${resource}/delete/multiple/`,
    data
  )
}
// Sim
function createSim(data) {
  return ClientChauffeur("root_service.service_path_create").post(`${resource}/sim/create/`, data)
}
function updateSim(id, data) {
  return ClientChauffeur("root_service.service_path_create").put(
    `${resource}/sim/retrieve/${id}/`,
    data
  )
}
function removeSim(id) {
  return ClientChauffeur("root_service.service_path_create").delete(
    `${resource}/sim/retrieve/${id}/`
  )
}

//Telecom Company

async function getTelecomCompanies(params) {
  return ClientChauffeur().get(`${resource}/telecom-vendor/list/`, { params })
}

function createTelecomCompany(data) {
  return ClientChauffeur("root_service.service_path_create").post(
    `${resource}/telecom-vendor/create/`,
    data
  )
}
function updateTelecomCompany(id, data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/telecom-vendor/retrieve/${id}/`,
    data
  )
}
function getTelecomCompany(id) {
  return ClientChauffeur("root_service.service_path_update").get(
    `${resource}/telecom-vendor/retrieve/${id}/`
  )
}
function removeTelecomCompany(id) {
  return ClientChauffeur("root_service.service_path_delete").delete(
    `${resource}/telecom-vendor/retrieve/${id}/`
  )
}
function getOrganizations(params) {
  return ClientChauffeur("root_service.service_path_update").get(
    `${resource}/organization/autocomplete/`,
    { params }
  )
}
const api = {
  gets,
  getDetaiDevice,
  updateStatusDevice,
  exportDevice,
  getOrganizations,
  getSims,
  getDetailSim,
  createDevice,
  updateDevice,
  removeDevice,
  remove,
  createSim,
  updateSim,
  updateStatusSim,
  updateMutil,
  removeSim,
  importSim,
  removeMutil,
  getTelecomCompanies,
  createTelecomCompany,
  updateTelecomCompany,
  getTelecomCompany,
  removeTelecomCompany
}
export default api
