import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/client/driver"

function gets(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/list/`, { params })
}
function get(id) {
  return Client("root_vehicle.service_path_view").get(`${resource}/retrieve/${id}/`)
}
function remove(id) {
  return Client("root_vehicle.service_path_view").delete(`${resource}/retrieve/${id}/`)
}
function sendInvitation(id) {
  return Client("root_vehicle.service_path_view").put(`${resource}/send-invitation/${id}/`)
}
function create(data) {
  return Client("root_service.service_path_create").post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client("root_service.service_path_create").put(`${resource}/retrieve/${id}/`, data)
}

function exportDriver(data) {
  return Client("root_service.service_path_create").post(`${resource}/export/`, data)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  sendInvitation,
  exportDriver
}
export default api
