/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import Login from "./Login"
import SendEmail from "./SendEmail"
import OTPCode from "./OTPCode"
import ResetPassword from "./ResetPassword"
import PasswordSuccess from "./PasswordSuccess"
import { EMAIL_TYPE } from "lib/Const"

const Main = () => {
  const [step, setStep] = useState(1)
  const [firstLogin, setFirstLogin] = useState(false)
  const [isLockedUser, setIsLockedUser] = useState(false)
  const [email, setEmail] = useState("")
  const [type, setType] = useState(EMAIL_TYPE.Reset)
  const [auth, setAuth] = useState(null)

  function onActionFromGetError(isLockedUser) {
    if (isLockedUser) {
      setType(EMAIL_TYPE.Reset)
    } else {
      setType(EMAIL_TYPE.Confirm)
    }
    setIsLockedUser(isLockedUser)
    setFirstLogin(!isLockedUser)
    setStep(2)
  }

  function onForgot() {
    setType(EMAIL_TYPE.Reset)
    setFirstLogin(false)
    setStep(2)
  }

  function onConfirmOTP(auth) {
    setAuth(auth)
    setStep(4)
  }

  function onSendEmail(email) {
    setEmail(email)
    setStep(3)
  }

  function onBackLogin() {
    setStep(1)
  }

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white md:mt-0 sm:max-w-md xl:p-0">
          {step === 1 && <Login onNext={onActionFromGetError} onForgot={onForgot} />}
          {step === 2 && (
            <SendEmail
              isFirstLogin={firstLogin}
              isLockedUser={isLockedUser}
              onNext={onSendEmail}
              onBack={onBackLogin}
            />
          )}
          {step === 3 && <OTPCode type={type} email={email} onNext={onConfirmOTP} />}
          {step === 4 && <ResetPassword auth={auth} onNext={() => setStep(5)} />}
          {step === 5 && <PasswordSuccess onNext={onBackLogin} />}
        </div>
      </div>
    </section>
  )
}

export default Main
