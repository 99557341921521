/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser, apiApps } from "api"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { setCookieAuth, getCookieAuth, setCookiesCallback } from "lib/cookie"
import { useStore } from "components/ui"
import { setEnums } from "lib/localstorage"
// import { isEmailValid } from "lib/validate"
import { ERROR_API_CODE, ENUMS_CONFIG } from "lib/Const"
import CustomButton from "./CustomButton"
import { iconHide, iconShow, iconEmail, iconPassword } from "lib/image"
import { changeLanguage } from "utils/i18n"
import "./index.css"
import { apiAms } from "api"

const Login = ({ onNext, onForgot }) => {
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const { access_token } = getCookieAuth()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const local = window.location.href.includes("local")

  const onChange = (v) => {
    setError("")
    setUsername(v)
  }

  const onFinish = async (e) => {
    e.preventDefault()
    // if (!isEmailValid(username)) {
    //   setError("Email is invalid!")
    //   return
    // }

    let res = {}
    try {
      setLoading(true)
      res = await apiUser.login({
        username,
        password
      })

      const { access_token, refresh_token } = res?.data
      let auth = {
        access_token,
        refresh_token
      }
      setCookieAuth(auth)
      window.location.reload()
    } catch (error) {
      const { error_code, error_message } = error
      if (error_code === ERROR_API_CODE.User_Verify) {
        onNext(false)
      } else if (error_code === ERROR_API_CODE.User_Locked) {
        onNext(true)
      } else {
        // if (error_message && Array.isArray(error_message) && error_message.length > 0) {
        //   setError(error_message[0])
        // } else {
        //   setError("Something went wrong!")
        // }
      }
    } finally {
      setLoading(false)
    }
  }

  const callListApi = async () => {
    setLoading(true)
    try {
      const _userInfo = apiUser.getUserInfo()
      const _application = apiApps.getAuthCode()
      const [userInfo, application] = await Promise.all([_userInfo, _application])

      localStorage.setItem("application", JSON.stringify(application?.data))
      const orgName = userInfo?.data?.organization?.name
      const defaultLanguage = userInfo?.data?.language || "en"

      // Prepare the base data object to be stored locally
      let baseData = {
        user: userInfo?.data,
        orgName: orgName,
        permission: [],
        access_token: access_token
      }
      // Set local enums and base data
      setEnums(ENUMS_CONFIG)
      await changeLanguage(defaultLanguage)
      // Set authentication data in the store
      setAuthStore(baseData)
      handleSetDataTranslateErrorMsg()
      setLoading(false)
      // Redirect to the dashboard page
      navigate("/chauffeur-connect")
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (error) setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  useEffect(() => {
    if (access_token) {
      callListApi()
    } else {
      if (!local) {
        window.location.href = `${process.env.REACT_APP_SSO_URL}`
      }
    }
  }, [access_token])

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              Email
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={username}
              type="email"
              name="email"
              id="email"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder="Enter email"
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconEmail}</span>
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-[#000000A6]">
              Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              onFocus={(e) => setError("")}
              value={password}
              type={showPass ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter password"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              required
            />
            <span className="relative float-left left-1 -top-[35px]">{iconPassword}</span>
            {password.length > 0 && (
              <span className="field-icon cursor-pointer" onClick={() => setShowPass(!showPass)}>
                {showPass ? iconShow : iconHide}
              </span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  required=""
                />
              </div>
              <div className="ml-3">
                <label htmlFor="remember" className="text-[14px] text-[#000000E0] font-[400]">
                  Remember me
                </label>
              </div>
            </div>
          </div>
          {error !== "" && <div className="margin-text-top text-red-500">{error}</div>}
          <CustomButton title="Sign In" loading={loading} disabled={error !== "" ? true : false} />
          {/* <div
            className="text-[14px] hover:underline hover:cursor-pointer text-[#000000E0] text-center"
            onClick={onForgot}
          >
            Forgot password?
          </div> */}
          <div>
            <p className="text-center text-[14px] text-[#000000A6] font-[400]">
              2023 Organic Mobility Inc. Alright Reserved
            </p>
            <p className="text-center">
              <span className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]">
                Privay & Policy
              </span>
              <span className="text-[14px] text-[#000000A6] font-[400]"> and </span>
              <span className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]">
                Terms of Service
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
